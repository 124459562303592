import React, { useState, useCallback, useEffect } from 'react';
import { WeekPicker, WeekPickerDateDirection } from '@esub-engineering/react-component-library';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  IUseWeekPickerProps,
  IWeekRange,
} from '@esub-engineering/react-component-library/dist/src/molecules/WeekPicker/types';
import { getDatesByWeekEnding } from '../../../timeCardManagement/utils/datesByWeekEnding';
import { preserveLocalDateTime } from '../../utils';

export const useWeekPicker = ({ startingDate, weekEnding }: IUseWeekPickerProps) => {
  const [weekStartDate, setWeekStartDate] = useState<Date>(startingDate);
  const [week, setWeek] = useState<IWeekRange>(() => {
    const { startDate, endDate } = getDatesByWeekEnding(weekEnding, startingDate);

    const weekRange: IWeekRange = {
      start: `${preserveLocalDateTime(startDate)}.000Z`,
      end: `${preserveLocalDateTime(endDate)}.000Z`,
    };
    return weekRange;
  });

  const setWeekDates = useCallback(
    (start: Date) => {
      const { startDate, endDate } = getDatesByWeekEnding(weekEnding, start);

      const weekRange: IWeekRange = {
        start: `${preserveLocalDateTime(startDate)}.000Z`,
        end: `${preserveLocalDateTime(endDate)}.000Z`,
      };

      return weekRange;
    },
    [weekEnding]
  );

  const onWeekChange = useCallback(
    (direction: WeekPickerDateDirection, dateToSet?: MaterialUiPickersDate) => {
      const date = dateToSet || weekStartDate;

      if (direction === WeekPickerDateDirection.INCREASE) {
        date.setDate(date.getDate() + 7);
      } else if (direction === WeekPickerDateDirection.DECREASE) {
        date.setDate(date.getDate() - 7);
      }
      const updatedWeekDates = setWeekDates(date);
      setWeek(updatedWeekDates);
      setWeekStartDate(date);

      return updatedWeekDates;
    },
    [weekStartDate, setWeekDates]
  );

  useEffect(() => {
    setWeek(setWeekDates(weekStartDate));
    // only needed to reset dates when week ending day changes by the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekEnding]);

  return {
    startDate: startingDate && weekEnding ? week.start : null,
    endDate: startingDate && weekEnding ? week.end : null,
    changeWeekView: onWeekChange,
    Picker: React.memo(WeekPicker),
  };
};
