import { TDaysOfWeek } from '@esub-engineering/react-component-library';

export const WEEKDAY_TO_NUMBER_MAPPING = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};
// This is needed to support the change of week ending day
export const weekDayValuesToSubtract = {
  MONDAY: 5,
  TUESDAY: 4,
  WEDNESDAY: 3,
  THURSDAY: 2,
  FRIDAY: 1,
  SATURDAY: 0,
  SUNDAY: 0,
};

// Get start and end dates based on company week ending day
export const getDatesByWeekEnding = (weekEnding: TDaysOfWeek, startingDate: Date) => {
  const transformWeekEndingToDay = (day: TDaysOfWeek) => {
    switch (day) {
      case TDaysOfWeek.SUNDAY:
        return 0;
      case TDaysOfWeek.MONDAY:
        return 1;
      case TDaysOfWeek.TUESDAY:
        return 2;
      case TDaysOfWeek.WEDNESDAY:
        return 3;
      case TDaysOfWeek.THURSDAY:
        return 4;
      case TDaysOfWeek.FRIDAY:
        return 5;
      default:
        return 6;
    }
  };

  const currentDate = new Date(startingDate);
  const dayOfWeek = currentDate.getDay();
  const weekEndingDay = transformWeekEndingToDay(weekEnding);

  if (dayOfWeek === weekEndingDay) {
    const start = new Date(currentDate);
    start.setDate(currentDate.getDate() - 6);
    return {
      startDate: start.toString(),
      endDate: currentDate.toString(),
    };
  }

  // 2024-1-4 Week starting day is the week ending day plus 1 day. DL
  const weekStartingDay = weekEndingDay === 0 ? 1 : weekEndingDay + 1;

  if (dayOfWeek === weekStartingDay) {
    const end = new Date(currentDate);
    end.setDate(currentDate.getDate() + 6);
    return {
      startDate: currentDate.toString(),
      endDate: end.toString(),
    };
  }

  let daysFromStart = 0;
  if (dayOfWeek < weekStartingDay) {
    daysFromStart = 7 - Math.abs(dayOfWeek - weekStartingDay);
  } else {
    daysFromStart = Math.abs(dayOfWeek - weekStartingDay);
  }

  currentDate.setDate(startingDate.getDate() - daysFromStart);

  const end = new Date(currentDate);
  end.setDate(currentDate.getDate() + 6);
  const startDate = currentDate.toString();
  const endDate = end.toString();
  return {
    startDate,
    endDate,
  };
};
